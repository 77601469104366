export const nav = [
    {
        text: "Home",
        path: "/",
    },
    {
        text: "Authors",
        path: "/authors",
    },
    {
        text: "Categories",
        path: "/categories",
    },
    {
        text: "Quote of the Day",
        path: "/quoteoftheday"
    },
  
]
export const footer =[
    {
        text: "Privacy Policy",
        path: "/privacy",
        type: "Privacy_Policy"
    },
    {
        text: "About Us",
        path: "/aboutus",
        type: "About_Us"
    },
    {
        text:"Contact Us",
        path: "/contactus",
        type: "Contact_Us"
    }
]