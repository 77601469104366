import React, { useState } from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import { nav } from '../data/Data';
import { footer } from '../data/Data';
import { Link } from 'react-router-dom';



export default function Footer() {
  const [navlist, setNavList] = useState(false);
  const [clickedType, setClickedType]= useState(null);

  const handleListClickNextNav = ()=>{
    setNavList(false);
  }
  const handleListClick = (type) => {
   setNavList(false);
      
      setClickedType(type);
    
  };


  return (
    <MDBFooter className='text-center text-lg-start text-muted' style={{ background: 'linear-gradient(to bottom, #ffffff 0%, #b1cbbb 100%)' }}>
      <MDBContainer>
        <MDBRow className='mt-4 justify-content-center'>

          
          <div className="col-12 col-md-6 col-lg-4 col-xl-3 text-center mt-0">
            <MDBCol>
              <h6>
                <MDBIcon className="me-4" />
                <img src="../images/landing_app_logo.png" alt='Navbar Logo' height='120' />
              </h6>
              <p>
                Step into tomorrow with renewed inspiration. Let each quote guide your journey towards greater understanding and fulfillment.
              </p>
            </MDBCol>
          </div>

          
          <div className="col-12 col-md-6 col-lg-4 col-xl-3 ">
            <MDBCol>
              <h6 className='text-uppercase fw-bold mb-3 d-flex justify-content-center align-items-center'>Useful links</h6>
              <div className=' d-flex justify-content-center align-items-center'>
                <ul className="list-unstyled"> 
                  {nav.map((list, index) => (
                    <li key={index} onClick={handleListClickNextNav} style={{ textDecoration: 'none', textAlign:'center' }}> 
                      <Link to={list.path}>{list.text}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </MDBCol>
          </div>

         
          <div className="col-12 col-lg-4 col-xl-3">
            <MDBCol>
              <h6 className='text-uppercase fw-bold mb-3 d-flex justify-content-center align-items-center'>More Useful links</h6>
              <div className='d-flex justify-content-center align-items-center'>
                <ul className="list-unstyled"> 
                  {footer.map((list, index) => (
                    <li key={index} onClick={()=>handleListClick(list.type)} style={{ textDecoration: 'none',textAlign:'center' }}> 
                      <Link  to={list.path}>{list.text}</Link>
                    </li>
                  ))}
                </ul>
               
              </div>
            </MDBCol>
          </div>
        </MDBRow>
      </MDBContainer>

      <div className='text-center p-3' style={{ backgroundColor: '#bdcebe' }}>
        <MDBContainer>
          <span>© {new Date().getFullYear()} We Inspire | All rights reserved</span>
        </MDBContainer>
      </div>
    </MDBFooter>
  );
}
