import React, { useState } from "react";
import { BrowserRouter as Router, Outlet } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import ScrolltoTop from "./components/scrollup/ScolltoTop";
import Footer from "./components/Footer";
import RoutesComponent from "./route/Routes";

function App() {
  const [clickedType, setClickedType] = useState(null);

  const handleTypeClick = (type) => {
    setClickedType(type);
  };

  return (
    <>
     <RoutesComponent />
    </>
  );
}

export default App;
